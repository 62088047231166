<template>
  <div class="authorization-modal">
    <div class="authorization-modal__wrapper">
      <p class="authorization-modal__title">Авторизация</p>
      <span class="authorization-modal__subtitle">Для бронирования авторизуйтесь или 
        <a class="authorization-modal__password-support-link" target="_blank" href="/register/">зарегистрируйтесь</a> на сайте
      </span>
      <div class="authorization-modal__input-area" :class="{error: error}">
        <input placeholder="Логин" class="authorization-modal__input" v-model="username" type="email"/>
        <input placeholder="Пароль" class="authorization-modal__input" v-model="password" type="password"/>
        <span class="authorization-modal__error"><span v-if="error">{{error}}</span></span>
      </div>
      <div class="authorization-modal__actions">
        <button
            class="yellow-button"
            @click="authorize(password, username)"
            :disabled="isLoading"
            :class="{'loading': isLoading}"
        >
          <span class="authorization-modal__come-in-title">Войти</span>
          <span class="loader"></span>
        </button>
        <a class="authorization-modal__password-support-link" target="_blank" href="/recover/">Забыли
          пароль?</a>
      </div>
    </div>
    <div class="overlay" @click="closeAuthorizationModal"></div>
  </div>
</template>

<script>
import {authorizationMapper} from '@/store/modules/authorization';
import { calendarMapper } from '@/store/modules/calendar';

export default {
  name: 'AuthorizationModal',
  data() {
    return {
      username: '',
      password: '',
      isLoading: false,
      error: null
    }
  },
  computed: {
    ...authorizationMapper.mapGetters(['loginInfo', 'userInfo']),
  },
  methods: {
    ...authorizationMapper.mapActions(['login', 'getPrincipalInfo']),
    ...calendarMapper.mapActions(['getReservedSeatsList']),
    closeAuthorizationModal() {
      this.$emit('closeAuthorizationModal', false, false);
    },
    async authorize(password, username) {
      this.isLoading = true;
      this.error = null;
      try {
        await this.login({password, username});
        this.$emit('processSeatClick');
        this.$emit('closeAuthorizationModal', false, true);
        await this.getReservedSeatsList();
        await this.getPrincipalInfo();
      } catch (e) {
        this.error = e.errorMessage;
      } finally {
        this.isLoading = false;
      }

    },
  },
}
</script>

<style lang="scss" src="@/styles/AuthorizationModal.scss">
</style>

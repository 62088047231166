import axios from 'axios';

// const api = {
//   url: `https://tickets.stage.metallurg.ru`,
// };

export default {
  async login(userInfo) {
    try {
      const url = `/webapi/auth/login`;
      const response = await axios.post(url, {
        "password": userInfo.password,
        "login": userInfo.username
      });

      return response.data;
    } catch (e) {
      throw e.response.data;
    }
  },

  async registerInit(registerInfo) {
    try {
      const url = `/webapi/person-registration/register`;
      const response = await axios.post(url, {
        birthday: registerInfo?.birthday,
        email: registerInfo?.email,
        firstName: registerInfo?.firstName,
        lastName: registerInfo?.lastName,
        middleName: registerInfo?.middleName,
        password: registerInfo?.password,
        phone: registerInfo?.phone
      });

      return response.data;
    } catch (e) {
      throw e.response.data;
    }
  },

  async registerConfirm(code) {
    try {
      const url = `/webapi/person-registration/confirm`;
      const response = await axios.put(url, {
        code: code,
      });

      return response.data;
    } catch (e) {
      throw e.response.data;
    }
  },

  async passwordRecoveryInit(email) {
    try {
      const url = `/webapi/pwd-recovery/init`;
      const response = await axios.put(url, {
        email: email,
      });

      return response.data;
    } catch (e) {
      throw e.response.data;
    }
  },

  async passwordRecoveryConfirm(passwordRecoveryConfirmInfo) {
    try {
      const url = `/webapi/pwd-recovery/confirm`;
      const response = await axios.put(url, passwordRecoveryConfirmInfo);

      return response.data;
    } catch (e) {
      throw e.response.data;
    }
  },

  async logout() {
    try {
      const url = `/webapi/auth/logout`;
      const response = await axios.post(url);

      return response.data;
    } catch {
      throw new Error('Failed to logout. Please try again later.');
    }
  },

  async getUserInfo() {
    try {
      const url = `/webapi/auth/me`;
      const response = await axios.get(url);

      return response.data;
    } catch {
      throw new Error('Failed to get user info. Please try again later.');
    }
  },

  async getPrincipalInfo() {
    try {
      const url = `/webapi/auth/principal-info`;
      const response = await axios.get(url);

      return response.data;
    } catch {
      throw new Error('Failed to get principal info. Please try again later.');
    }
  },

  async simpleAuth(simpleAuthInfo) {
    const formData = new FormData();
    formData.append('firstName', simpleAuthInfo.firstName);
    formData.append('lastName', simpleAuthInfo.lastName);
    formData.append('email', simpleAuthInfo.email);
    formData.append('phone', simpleAuthInfo.phoneNumber);

    try {
      const url = `/webapi/simple-auth/attempt`;
      const response = await axios.post(url, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return response;
    } catch (e) {
      throw e.response;
    }
  },

  async confirmAccount(smsInfo) {
    const formData = new FormData();
    formData.append('smsCode', smsInfo.smsCode);
    formData.append('smsHashId', smsInfo.smsHashId);

    try {
      const url = `/webapi/simple-auth/confirm-account`;
      const response = await axios.post(url, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return response.data;
    } catch (e) {
      throw e.response.data;
    }
  },

  async simpleAuthLogout() {
    try {
      const url = `/webapi/simple-auth/logout`;
      const response = await axios.post(url);

      return response.data;
    } catch {
      throw new Error('Failed to logout. Please try again later.');
    }
  }
};

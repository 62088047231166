<template>
  <div class="header js-header animate" :class="{ 'show-menu': isShowMobileMenu, 'fixed': isScrollOverHeader}">
    <a href="/" class="header__logo">
      <div class="header__logo-img">
        <img src="https://metallurg.ru/_assets/redesign2020/img/official/small-logo.svg" alt="Логотип ХК Металлург" loading="lazy" width="36" height="24">
      </div>
      <div class="header__logo-text">
        <svg class="text"><use xlink:href="#ic_logo-text"></use></svg> 
        <span class="sub-text">Трехкратный&nbsp;обладатель Кубка&nbsp;Гагарина</span>
      </div>
    </a>

    <div class="header__burger-menu js-burger-menu" tabindex="-1">
            
            <button type="button" class="js-close-menu close" tabindex="-1" @click="closeMenu()">
                <svg class="icon">
                    <use xlink:href="#ic_close"></use>
                </svg>
            </button>
            <div class="header__user">
                <div class="header__user-panel">
                    <div class="user-info">
                        <div v-if="this.userEmail" style="display: flex; align-items: center; width: 100%">
                            <svg class="user-info-img">
                                <use xlink:href="#ic_authorization"></use>
                            </svg>
                            <span class="user-info-name">{{ this.userEmail }}</span>
                        </div>
                        <button v-else type="button" class="header__authorization js-popup-auth-open" tabindex="-1" @click="showAuthorizationModal">
                            <svg class="icon">
                                <use xlink:href="#ic_authorization"></use>
                            </svg>
                            <span>Вход для болельщиков</span>
                        </button>
                    </div>
                    <ul v-if="this.userEmail" class="user-menu">
<!--                      <li class="user-menu__item"><a href="https://metallurg.ru/profile/" tabindex="-1">Личный кабинет</a></li> -->
                      <li class="user-menu__item"><a href="/orders">Заказы</a></li>
                      <li class="user-menu__item" @click="logOff()"><a>Выйти</a></li>  
                    </ul>  
                </div>
            </div>
            
            <ul class="header__menu-list">
                <li class="js-list-item  menu-dropdown" :class="{opened: isActive_News}" @click="isActive_News =!isActive_News">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                            <span>Новости</span>
                            <span class="arrow">
                                <svg class="icon"><use xlink:href="#ic_chevron"></use></svg>
                            </span>
                    </button>
                            <ul>
                                            <li class="">
                            <a href="https://metallurg.ru/press/club-news/" tabindex="-1">Новости клуба</a>
                        </li>
                                            <li class="">
                            <a href="https://metallurg.ru/press/other-news/" tabindex="-1">Новости хоккея</a>
                        </li>
                                    </ul>
                </li>
                <li class="js-list-item  menu-dropdown" :class="{opened: isActive_Club}" @click="isActive_Club =!isActive_Club">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                            <span>Клуб</span>
                            <span class="arrow">
                                <svg class="icon">
                                    <use xlink:href="#ic_chevron"></use>
                                </svg>
                            </span>
                    </button>
                            <ul>
                                            <li class="">
                                                <a href="https://metallurg.ru/club/management/" tabindex="-1">Руководство</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/club/fame/" tabindex="-1">Зал славы</a>
                                            </li>
                                            <li class="">
                                                <a href="http://school.metallurg.ru/" tabindex="-1">Хоккейная школа</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/club/partners/" tabindex="-1">Партнеры клуба</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/club/contacts/" tabindex="-1">Контакты</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/club/vacancies/" tabindex="-1">Вакансии</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/club/history/" tabindex="-1">История клуба</a>
                                            </li>
                            </ul>
                </li>
                <li class="js-list-item  menu-dropdown" :class="{opened: isActive_Team}" @click="isActive_Team =!isActive_Team">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                                        <span>Команда</span>
                                        <span class="arrow">
                                            <svg class="icon">
                                                <use xlink:href="#ic_chevron"></use>
                                            </svg>
                                        </span>
                    </button>
                            <ul>
                                            <li class="">
                                                <a href="https://metallurg.ru/team/roster/" tabindex="-1">Состав команды</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/team/coaches/" tabindex="-1">Тренерский штаб</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/team/players-stats/" tabindex="-1">Статистика игроков</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/team/teams-stats/" tabindex="-1">Статистика чемпионата</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/team/transfers" tabindex="-1">Трансферы</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/team/hospital" tabindex="-1">Лазарет</a>
                                            </li>
                            </ul>
                </li>
                <li class="js-list-item ">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                            <a href="https://metallurg.ru/matches/" tabindex="-1">Матчи</a>
                    </button>
                </li>
                <li class="js-list-item  menu-dropdown" :class="{opened: isActive_Media}" @click="isActive_Media =!isActive_Media">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                                        <span>Медиа</span>
                                        <span class="arrow">
                                            <svg class="icon">
                                                <use xlink:href="#ic_chevron"></use>
                                            </svg>
                                        </span>
                    </button>
                            <ul>
                                            <li class="">
                                                <a href="https://metallurg.ru/media/photo/" tabindex="-1">Фотогалерея</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/media/video/" tabindex="-1">Видеоматериалы</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/media/magazine/" tabindex="-1">Журнал «Металлург»</a>
                                            </li>
                            </ul>
                </li>
                <li class="js-list-item  menu-dropdown" :class="{opened: isActive_Fan}" @click="isActive_Fan =!isActive_Fan">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                                        <span>Фан-зона</span>
                                        <span class="arrow">
                                            <svg class="icon">
                                                <use xlink:href="#ic_chevron"></use>
                                            </svg>
                                        </span>
                    </button>
                            <ul>
                                            <li class="">
                                                <a href="https://metallurg.ru/fan/toto/" tabindex="-1">Тото</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/fan/photo" tabindex="-1">Наши болельщики</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/fan/compare" tabindex="-1">Сравнение команд</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/fan/auction/" tabindex="-1">Аукцион</a>
                                            </li>
                                            <li class="">
                                            <a href="https://metallurg.ru/fan/voted/" tabindex="-1">Голосования</a>
                                            </li>
                            </ul>
                </li>
                <li class="js-list-item  menu-dropdown" :class="{opened: isActive_Shop}" @click="isActive_Shop =!isActive_Shop">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                                        <span>Магазин</span>
                                        <span class="arrow">
                                            <svg class="icon">
                                                <use xlink:href="#ic_chevron"></use>
                                            </svg>
                                        </span>
                    </button>
                            <ul>
                                            <li class="">
                                                <a href="https://metallurg.ru/eshop/2496/" tabindex="-1">Канцтовары</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/eshop/311/" tabindex="-1">Аксессуары</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/eshop/316/" tabindex="-1">Головные уборы</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/eshop/386/" tabindex="-1">Детям</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/eshop/335/" tabindex="-1">Для дома и офиса</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/eshop/323/" tabindex="-1">Одежда</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/eshop/328/" tabindex="-1">Сувениры</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/eshop/339/" tabindex="-1">Хоккейные свитера</a>
                                            </li>
                            </ul>
                </li>
                <li class="js-list-item  menu-dropdown" :class="{opened: isActive_Tickets}" @click="isActive_Tickets =!isActive_Tickets">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                                        <span>Билеты</span>
                                        <span class="arrow">
                                            <svg class="icon">
                                                <use xlink:href="#ic_chevron"></use>
                                            </svg>
                                        </span>
                    </button>
                            <ul>
                                            <li class="">
                                                <a href="http://tickets.metallurg.ru/" tabindex="-1">Купить билеты онлайн</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/tickets/sale/" tabindex="-1">Карта точек продаж</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/tickets/" tabindex="-1">Билетная программа</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/tickets/abonements/" tabindex="-1">Абонементная программа</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/tickets/vip-lodges/" tabindex="-1">VIP-ложи</a>
                                            </li>
                            </ul>
                </li>
                <li class="js-list-item  menu-dropdown" :class="{opened: isActive_Arena}" @click="isActive_Arena =!isActive_Arena">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                                        <span>Арена</span>
                                        <span class="arrow">
                                            <svg class="icon">
                                                <use xlink:href="#ic_chevron"></use>
                                            </svg>
                                        </span>
                    </button>
                            <ul>
                                            <li class="">
                                                <a href="https://metallurg.ru/arena/" tabindex="-1">Об арене</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/arena/play-room/" tabindex="-1">Игровая комната</a>
                                            </li>
                                            <li class="">
                                                <a href="https://metallurg.ru/arena/restaurant/" tabindex="-1">Ресторан «Хет-трик»</a>
                                            </li>
                            </ul>
                </li>
            </ul>
<!--
            <div class="header__search js-header-search">
                    <form action="/search/">
                        <div class="header__search-container">
                            <input type="text" name="q" placeholder="Поиск по сайту" tabindex="-1">
                            <button type="submit" tabindex="-1">
                                <svg class="icon">
                                    <use xlink:href="#ic_search"></use>
                                </svg>
                            </button>
                        </div>
                    </form>
            </div>
-->            
            <div class="header__socials">
                <a href="http://vk.com/hcmetallurg" target="_blank" tabindex="-1" class="header__socials-link header__socials-link--vk">
                    <svg class="vk">
                        <use xlink:href="#ic_vk"></use>
                    </svg>
                </a>
                <!--
                <a href="http://www.facebook.com/metallurg.ru" target="_blank" tabindex="-1" class="header__socials-link header__socials-link--fb">
                    <svg class="fb">
                        <use xlink:href="#ic_fb"></use>
                    </svg>
                </a>
                <a href="http://instagram.com/hcmetallurg" target="_blank" tabindex="-1" class="header__socials-link header__socials-link--inst">
                    <svg class="inst">
                        <use xlink:href="#ic_inst"></use>
                    </svg>
                </a>
                -->
                <a href="https://twitter.com/MetallurgMgn" target="_blank" tabindex="-1" class="header__socials-link header__socials-link--twitter">
                    <svg class="twitter">
                        <use xlink:href="#ic_twitter"></use>
                    </svg>
                </a>
                <a href="http://www.youtube.com/hcmetallurg" target="_blank" tabindex="-1" class="header__socials-link header__socials-link--ytb">
                    <svg class="youtube">
                        <use xlink:href="#ic_youtube"></use>
                    </svg>
                </a>
                <a href="https://metallurg.ru/rss/" target="_blank" tabindex="-1" class="header__socials-link header__socials-link--rss">
                    <svg class="rss">
                        <use xlink:href="#ic_rss"></use>
                    </svg>
                </a>
            </div>
            <!--
            <div class="header__lang-switcher">
                <a href="https://www.en.metallurg.ru" class="eng " tabindex="-1">
                    <img src="https://metallurg.ru/_assets/redesign2020/img/official/eng.png" alt="English version" title="English version" width="24" height="16" loading="lazy">
                </a>
                <a href="" class="rus active" tabindex="-1">
                    <img src="https://metallurg.ru/_assets/redesign2020/img/official/rus.png" alt="Русская версия" title="Русская версия" width="24" height="16" loading="lazy">
                </a>
            </div>
            -->
        </div>

        <div class="header__menu">

            <div class="header__menu-top">
                <div class="header__socials">
                    <a href="http://vk.com/hcmetallurg" target="_blank" class="header__socials-link header__socials-link--vk">
                        <svg class="vk">
                            <use xlink:href="#ic_vk"></use>
                        </svg>
                    </a>
                    <!--
                    <a href="http://www.facebook.com/metallurg.ru" target="_blank" class="header__socials-link header__socials-link--fb">
                        <svg class="fb">
                            <use xlink:href="#ic_fb"></use>
                        </svg>
                    </a>
                    <a href="http://instagram.com/hcmetallurg" target="_blank" class="header__socials-link header__socials-link--inst">
                        <svg class="inst">
                            <use xlink:href="#ic_inst"></use>
                        </svg>
                    </a>
                    -->
                    <a href="https://twitter.com/MetallurgMgn" target="_blank" class="header__socials-link header__socials-link--twitter">
                        <svg class="twitter">
                            <use xlink:href="#ic_twitter"></use>
                        </svg>
                    </a>
                    <a href="http://www.youtube.com/hcmetallurg" target="_blank" class="header__socials-link header__socials-link--ytb">
                        <svg class="youtube">
                            <use xlink:href="#ic_youtube"></use>
                        </svg>
                    </a>
                    <a href="https://metallurg.ru/rss/" target="_blank" class="header__socials-link header__socials-link--rss">
                        <svg class="rss">
                            <use xlink:href="#ic_rss"></use>
                        </svg>
                    </a>
                </div>

                <div class="header__user">
                    <div class="header__user-panel">
                        <button v-if="this.userEmail" type="button" class="header__authorization" style="z-index: 1;">
                                <svg class="icon">
                                    <use xlink:href="#ic_authorization"></use>
                                </svg>
                                <span>{{ this.userEmail }}</span>
                        </button>
                        <button v-else type="button" class="header__authorization js-popup-auth-open" @click="showAuthorizationModal">
                                <svg class="icon">
                                    <use xlink:href="#ic_authorization"></use>
                                </svg>
                                <span >Вход для болельщиков</span>
                        </button>
                        <ul v-if="this.userEmail" class="user-menu">
<!--                          <li class="user-menu__item"><a href="https://metallurg.ru/profile/" tabindex="-1">Личный кабинет</a></li> -->
                          <li class="user-menu__item"><a href="/orders">Заказы</a></li>
                          <li class="user-menu__item" @click="logOff()"><a>Выйти</a></li>  
                        </ul>    
                    </div>
                </div>
                <a class="header__fox-link" href="http://mhl.metallurg.ru/" target="_blank">
                        <img src="https://metallurg.ru/_assets/redesign2020/img/official/fox-link.png" alt="">
                        <span>Сайт ХК «Стальные Лисы»</span>
                </a>
                <!--
                <div class="header__lang-switcher">
                    <a href="https://www.en.metallurg.ru" type="button" class="eng">
                        <img src="https://metallurg.ru/_assets/redesign2020/img/official/eng.png" alt="English version" title="English version" width="24" height="16" loading="lazy">
                    </a>
                    <a type="button" class="rus active">
                        <img src="https://metallurg.ru/_assets/redesign2020/img/official/rus.png" alt="Русская версия" title="Русская версия" width="24" height="16" loading="lazy">
                    </a>
                </div>
                -->
            </div>

            <div class="header__menu-bottom">
                
                <ul class="header__menu-list">
                    <li class=" menu-dropdown">
                            <span>Новости</span>
            
            
                            <ul>
                        <div class="gap"></div>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/press/club-news/"><a href="https://metallurg.ru/press/club-news/">Новости клуба</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/press/other-news/"><a href="https://metallurg.ru/press/other-news/">Новости хоккея</a></li>
                                    </ul>
                    </li>
                    <li class=" menu-dropdown">
                            <span>Клуб</span>
            
            
                            <ul>
                        <div class="gap"></div>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/club/management/"><a href="https://metallurg.ru/club/management/">Руководство</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/club/fame/"><a href="https://metallurg.ru/club/fame/">Зал славы</a></li>
                                            <li class=" js-top-submenu-item" data-url="http://school.metallurg.ru/"><a href="http://school.metallurg.ru/">Хоккейная школа</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/club/partners/"><a href="https://metallurg.ru/club/partners/">Партнеры клуба</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/club/contacts/"><a href="https://metallurg.ru/club/contacts/">Контакты</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/club/vacancies/"><a href="https://metallurg.ru/club/vacancies/">Вакансии</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/club/history/"><a href="https://metallurg.ru/club/history/">История клуба</a></li>
                                    </ul>
                    </li>
                    <li class=" menu-dropdown">
                            <span>Команда</span>
            
            
                        <ul>
                            <div class="gap"></div>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/team/roster/"><a href="https://metallurg.ru/team/roster/">Состав команды</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/team/coaches/"><a href="https://metallurg.ru/team/coaches/">Тренерский штаб</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/team/players-stats/"><a href="https://metallurg.ru/team/players-stats/">Статистика игроков</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/team/teams-stats/"><a href="https://metallurg.ru/team/teams-stats/">Статистика чемпионата</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/team/transfers"><a href="https://metallurg.ru/team/transfers">Трансферы</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/team/hospital"><a href="https://metallurg.ru/team/hospital">Лазарет</a></li>
                        </ul>
                    </li>
                    <li class="">
                            <a href="https://metallurg.ru/matches/">Матчи</a>
            
            
                    </li>
                    <li class=" menu-dropdown">
                            <span>Медиа</span>
            
            
                            <ul>
                                <div class="gap"></div>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/media/photo/"><a href="https://metallurg.ru/media/photo/">Фотогалерея</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/media/video/"><a href="https://metallurg.ru/media/video/">Видеоматериалы</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/media/magazine/"><a href="https://metallurg.ru/media/magazine/">Журнал «Металлург»</a></li>
                            </ul>
                    </li>
                    <li class=" menu-dropdown">
                            <span>Фан-зона</span>
            
            
                            <ul>
                                <div class="gap"></div>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/fan/toto/"><a href="https://metallurg.ru/fan/toto/">Тото</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/fan/photo"><a href="https://metallurg.ru/fan/photo">Наши болельщики</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/fan/compare"><a href="https://metallurg.ru/fan/compare">Сравнение команд</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/fan/auction/"><a href="https://metallurg.ru/fan/auction/">Аукцион</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/fan/voted/"><a href="https://metallurg.ru/fan/voted/">Голосования</a></li>
                            </ul>
                    </li>
                    <li class=" menu-dropdown">
                            <a href="https://metallurg.ru/eshop/">Магазин</a>
            
            
                            <ul>
                                <div class="gap"></div>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/eshop/2496/"><a href="https://metallurg.ru/eshop/2496/">Канцтовары</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/eshop/311/"><a href="https://metallurg.ru/eshop/311/">Аксессуары</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/eshop/316/"><a href="https://metallurg.ru/eshop/316/">Головные уборы</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/eshop/386/"><a href="https://metallurg.ru/eshop/386/">Детям</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/eshop/335/"><a href="https://metallurg.ru/eshop/335/">Для дома и офиса</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/eshop/323/"><a href="https://metallurg.ru/eshop/323/">Одежда</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/eshop/328/"><a href="https://metallurg.ru/eshop/328/">Сувениры</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/eshop/339/"><a href="https://metallurg.ru/eshop/339/">Хоккейные свитера</a></li>
                            </ul>
                    </li>
                    <li class=" menu-dropdown">
                            <span>Билеты</span>
            
            
                            <ul>
                                <div class="gap"></div>
                                            <li class=" js-top-submenu-item" data-url="http://tickets.metallurg.ru/"><a href="http://tickets.metallurg.ru/">Купить билеты онлайн</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/tickets/sale/"><a href="https://metallurg.ru/tickets/sale/">Карта точек продаж</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/tickets/"><a href="https://metallurg.ru/tickets/">Билетная программа</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/tickets/abonements/"><a href="https://metallurg.ru/tickets/abonements/">Абонементная программа</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/tickets/vip-lodges/"><a href="https://metallurg.ru/tickets/vip-lodges/">VIP-ложи</a></li>
                            </ul>
                    </li>
                    <li class=" menu-dropdown">
                            <span>Арена</span>
            
            
                            <ul>
                                <div class="gap"></div>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/arena/"><a href="https://metallurg.ru/arena/">Об арене</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/arena/play-room/"><a href="https://metallurg.ru/arena/play-room/">Игровая комната</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://metallurg.ru/arena/restaurant/"><a href="https://metallurg.ru/arena/restaurant/">Ресторан «Хет-трик»</a></li>
                            </ul>
                    </li>
                </ul>

            <div class="header__search-button-off"></div>
              <!--  <button type="button" class="js-show-search header__search-button">
                        <svg class="icon">
                            <use xlink:href="#ic_search"></use>
                        </svg>
                        <svg class="close">
                            <use xlink:href="#ic_close"></use>
                        </svg> 
                </button> -->
                    <div class="header__user-panel header__user-panel--bottom">
                        <button v-if="this.userEmail" type="button" class="header__authorization" style="z-index: 1;">
                                <svg class="icon">
                                    <use xlink:href="#ic_authorization"></use>
                                </svg>
                                <span>{{ this.userEmail }}</span>
                        </button>
                        <button v-else type="button" class="header__authorization js-popup-auth-open" @click="showAuthorizationModal">
                            <svg class="icon">
                                <use xlink:href="#ic_authorization"></use>
                            </svg>
                        </button>
                        <ul v-if="this.userEmail" class="user-menu">
<!--                          <li class="user-menu__item"><a href="https://metallurg.ru/profile/" tabindex="-1">Личный кабинет</a></li> -->
                          <li class="user-menu__item"><a href="/orders">Заказы</a></li>
                          <li class="user-menu__item" @click="logOff()"><a>Выйти</a></li>  
                        </ul> 
                    </div>
                <a href="http://mmk.ru/" target="_blank" class="header__mmk-link">
                    <img src="https://metallurg.ru/_assets/redesign2020/img/official/mmk.png" alt="ММК">
                </a>
            </div>

        </div>
<!--
        <div class="header__search js-header-search header__search-desktop">
                <form action="/search/">
                    <div class="header__search-container">
                        <input type="text" name="q" placeholder="Поиск по сайту">
                        <button type="submit">Найти                            <span class="loader"></span>
                        </button>
                    </div>
                </form>
        </div>
-->        
        
        <div class="header__mobile-menu">
            <a href="https://metallurg.ru/matches/">Матчи</a>
            <a href="https://tickets.metallurg.ru/" target="_blank">Билеты</a>
            <button type="button" class="js-hamburger-btn" @click="isShowMobileMenu = !isShowMobileMenu">   
                <div class="burger"><span></span></div>
                Меню            </button>
        </div>
  </div>
</template>

<script>
import { authorizationMapper } from '@/store/modules/authorization';
import router from "@/router";

export default {
  name: 'Header',
  data() {
    return {
      isShowMobileMenu: false,
      isActive_News: false,
      isActive_Club: false,
      isActive_Team: false,
      isActive_Media: false,
      isActive_Fan: false,
      isActive_Shop: false,
      isActive_Tickets: false,
      isActive_Arena: false,
      isScrollOverHeader: false
    }
  },
  mounted() {
    // Setup the event listener and execute it once in case we are already scrolled
    window.addEventListener('scroll', this.scrollHandler);
    this.scrollHandler();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    ...authorizationMapper.mapActions(['logout']),
    showAuthorizationModal() {
      this.$emit('showAuthorizationModal', true);
    },
    async logOff() {
      await this.logout();
      router.push({ name: 'main' });
      this.closeMenu();
    },
    closeMenu() {
      this.isShowMobileMenu = false;  
      document.body.style.overflow = 'auto';
    },
    Burger_menu: function() {
      this.isActive = !this.isActive;
    },
    scrollHandler() {
      this.isScrollOverHeader = window.pageYOffset > 80;
    }
    
  },
  computed: {
    ...authorizationMapper.mapGetters(['userInfo']),
    userEmail() {
      return this.userInfo?.userEmail;
    }
  },
  watch: {
      isShowMobileMenu() {
        if (this.isShowMobileMenu) {
          document.body.style.overflow = 'hidden';
          const burgerMenu = document.getElementsByClassName('header__burger-menu');
          for (let i = 0; i < burgerMenu.length; i++) {
            burgerMenu[i].style.overflow = 'auto';
          }
        }
      },
  }
}
</script>

<template>
  <div class="content">
    <div class="orders">
      <div v-if="!localOrders" class="orders__spinner">
        <half-circle-spinner
          v-if="isContentLoading"
          :animation-duration="2000"
          :size="60"
          :color="'#0a275f'"
        />
      </div>
      <div v-else>
<!--        <h1>Мои заказы</h1>-->
        <div v-if="localOrders && localOrders.length">
          <div  class="orders__table-header">
            <div class="order-item__main-info-section">
              <div class="order-item__info-item">
                <span style="min-width: 77px;" class="orders__info-item-text">{{ `№ заказа` }}</span>
              </div>
              <div class="order-item__info-item">
                <span style="min-width: 120px;" class="orders__info-item-text">{{ `Дата заказа ` }}</span>
              </div>
              <div class="order-item__info-item">
                <span style="min-width: 210px;" class="orders__info-item-text">{{ `Мероприятие` }}</span>
              </div>
            </div>
            <div class="order-item__date-section">
              <div class="order-item__info-item">
                <span class="orders__info-item-text">{{ `Дата мероприятия` }}</span>
              </div>
            </div>
            <div class="order-item__status-section">
              <div class="order-item__info-item">
                <span class="orders__info-item-text">{{ `Статус заказа` }}</span>
              </div>
            </div>
          </div>
          <div v-for="order in localOrders" :key="order.id">
            <OrderItem :order="order" />
          </div>
          <div v-if="localOrders.length < elementsCount" class="order-item__get-more-section">
            <button @click="getMoreOrders" type="button" class="order-item__get-more-button">Больше заказов</button>
          </div>
        </div>
        <div v-else>
          <span class="orders__no-orders">У вас пока нет заказов</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderMapper } from '@/store/modules/order';
import orders from '@/mocks/orders.json';
import orders1 from '@/mocks/orders1.json';

import { HalfCircleSpinner } from 'epic-spinners';
import OrderItem from './OrderItem.vue';

const FIRST_PAGE = 1;
const SIZE = 25;

export default {
  name: 'Orders',
  data() {
    return {
      localOrders: [],
      orders,
      orders1,
      pageNumber: 1,
      elementsCount: 0,
      isContentLoading: false,
    }
  },
  components: {
    OrderItem,
    HalfCircleSpinner
  },
  async mounted() {
    try {
      this.isContentLoading = true;
      await this.getOrdersList([FIRST_PAGE, SIZE]);
    } catch(error) {
      if (error.status === 502) {
        this.errorMessage = "Ошибка связи с сервером.";
        this.isErrorPopupShown = true;
      }
    } finally {
      this.isContentLoading = false;
    }
    this.elementsCount = this.ordersList.totalElements;
    this.localOrders = this.ordersList.content;
  },
  computed: {
    ...orderMapper.mapGetters(['ordersList']),
  },  
  methods: {
    ...orderMapper.mapActions(['getOrdersList', 'getOrdersList2']),
    async getMoreOrders() {
      try {

        const x = await this.getOrdersList2([this.pageNumber + 1, SIZE]);
        this.pageNumber = x.number;
        this.localOrders = [...this.localOrders, ...x.content];
      } catch(error) {
        if (error.status === 502) {
          this.errorMessage = "Ошибка связи с сервером.";
          this.isErrorPopupShown = true;
        }
      }
    }
  },
}
</script>

<style lang="scss" src="@/styles/Orders.scss">
</style>

<template>
    <div class="registration-form js-form-reg">
        <div class="registration-form__wrapper">
            <h1 class="registration-form__title">Регистрация</h1>
            <div class="registration-form__inner">
                <div class="registration-form__cart-form">
                    <div v-if="!stageConfirm">
                        <div class="js-field registration-form__element is-required">
                            <label>Имя&nbsp;*</label>
                            <div class="registration-form__element-right">
                                <input type="text" name="firstName" value="" v-model="firstname" placeholder="Введите имя"
                                    class="js-input">
                                <div class="js-error-text registration-form__cart-form-error">
                                    <span v-if="error && error?.errorKey === 'firstName'">{{error.errorMessage}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="js-field registration-form__element is-required">
                            <label>Фамилия&nbsp;*</label>
                            <div class="registration-form__element-right">
                                <input type="text" name="lastName" value="" v-model="lastname"
                                    placeholder="Введите фамилию" class="js-input">
                                <div class="js-error-text registration-form__cart-form-error">
                                    <span v-if="error && error?.errorKey === 'lastName'">{{error.errorMessage}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="js-field registration-form__element is-required">
                            <label>Отчество</label>
                            <div class="registration-form__element-right">
                                <input type="text" name="lastName" value="" v-model="middlename"
                                    placeholder="Введите отчество" class="js-input">
                                <div class="js-error-text registration-form__cart-form-error">
                                    <span v-if="error && error?.errorKey === 'middleName'">{{error.errorMessage}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="js-field registration-form__element is-required" :class="{ 'error': !isPasswordValid(password)}">
                            <label>Пароль&nbsp;*</label>
                            <div class="registration-form__element-right">
                                <input type="password" name="password" v-model="password" id="password_input_register"
                                    class="js-input" placeholder="Придумайте пароль">
                                <div class="js-error-text registration-form__cart-form-error">
                                    <span v-if="(password.length && !isPasswordValid(password)) || (error && error?.errorKey === 'password')">
                                        {{error?.errorMessage ?? 'Пароль должен быть от 8 символов, содержать минимум один заглавный символ, один прописной и одну цифру.'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="js-field registration-form__element is-required" :class="{ 'error': !isEmailValid()}">
                            <label>E-mail&nbsp;*</label>
                            <div class="registration-form__element-right">
                                <input type="email" inputmode="email" name="email" value="" placeholder="Введите e-mail"
                                    class="js-input" v-model="email">
                                <div class="js-error-text registration-form__cart-form-error">
                                    <span v-if="(email.length && !isEmailValid()) || (error && error?.errorKey === 'email')">
                                        {{error?.errorMessage ?? 'Неверный формат почты.'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="js-field registration-form__element is-required">
                            <label>Мобильный телефон&nbsp;*</label>
                            <div class="registration-form__element-right">
                                <input type="text" inputmode="numeric" name="phone" value=""
                                    placeholder="79119119999" v-model="phone" class="js-input" @keydown="phoneMask" @change="phoneChange($event)">
                                <div class="js-error-text registration-form__cart-form-error">
                                    <span v-if="error && error?.errorKey === 'phone'">{{error.errorMessage}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="js-field registration-form__element is-required">
                            <label>Дата рождения&nbsp;*</label>
                            <div class="registration-form__element-right">
                                <input type="date" name="birthday" value="" v-model="birthday"
                                    placeholder="Введите дату рождения" class="js-input">
                                <div class="js-error-text registration-form__cart-form-error">
                                    <span v-if="error && error?.errorKey === 'birthday'">{{error.errorMessage}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="js-field registration-form__element">
                            <div class="registration-form__checkbox-list">
                                <div class="registration-form__checkbox-item consent-checkbox">
                                    <input type="checkbox" name="approve" value="Y" id="approve" v-model="agreement"
                                        class="js-input visually-hidden">
                                    <label class="registration-form__checkbox-label" for="approve">Я согласен с <span
                                            class="tablet-rules-button js-rules-button">правилами сайта</span> и
                                        согласен на <a class="registration-form__rules-link link"
                                            href="/privacy-policy.pdf" target="_blank">обработку</a> моих персональных
                                        данных</label>
                                    <span class="registration-form__checkbox-custom"></span>
                                </div>
                            </div>
                        </div>
                        <button 
                            class="yellow-button registration-form__submit" 
                            type="button"
                            @click="startRegister(login)"
                            :class="{'loading': isLoading}"
                            :disabled="isLoading || !agreement || !isEmailValid(email) || !isPasswordValid(password) || !firstname.length || !lastname.length || !birthday.length"
                        >
                            Зарегистрироваться <span class="loader"></span>
                        </button>
                    </div>
                    <div class="js-success" v-if="stageConfirm && !stageSuccess">
                        <h3>Уважаемый пользователь!</h3>
                        <p>
                            На ваш адрес электронной почты направлено письмо со ссылкой для активации вашего
                            аккаунта.<br>
                            На ваш мобильный телефон отправлено sms-сообщение с кодом авторизации. </p>
                        <div class="js-field registration-form__element is-required">
                            <label>Код подтверждения&nbsp;*</label>
                            <div class="registration-form__element-right">
                                <input type="text" name="code" value="" v-model="code"
                                    placeholder="Введите код" class="js-input">
                                <div class="js-error-text registration-form__cart-form-error">
                                    <span v-if="error && error?.errorKey === 'code'">{{error.errorMessage}}</span>
                                </div>
                            </div>
                        </div>
                        <button 
                            class="registration-form__submit yellow-button" 
                            type="button"
                            @click="confirmRegister()"
                            :disabled="isLoading || !code.length"
                            :class="{'loading': isLoading}"
                        >
                            Подтвердить <span class="loader"></span>
                        </button>
                    </div>
                    <div v-if="stageSuccess" style="text-align: center;">
                        <h3 style="margin-bottom: 16px;">Успешная регистрация!</h3>
                        <a href="/" class="yellow-button go-to-auth">Авторизоваться</a>
                    </div>
                </div>
                <div class="registration-form__rules js-rules">
                    <div class="registration-form__rules-wrapper js-rules-wrapper">
                        <h3>Правила сайта</h3>
                        <p>
                            Приветствуются любые обсуждения, касающиеся хоккея. </p>
                        <p>
                            На сайте запрещено любое прямое либо косвенное оскорбление как оппонентов так и работников
                            «ХК Металлург». </p>
                        <p>
                            Сообщения, содержащие ненормативную лексику, удаляются целиком. </p>
                        <p>
                            Сообщения, содержащие ссылки на сайты и материалы сомнительного морально-этического
                            содержания, удаляются целиком. </p>
                        <p>
                            Администрация сайта оставляет за собой право ограничивать доступ посетителю сайта за
                            нарушение вышеуказанных правил. </p>
                        <p>
                            Администрация сайта в праве рассылать информационные материалы пользователям. </p>
                        <p>
                            По вопросам работы сайта, с жалобами и предложениями обращаться <a
                                class="registration-form__rules-link link"
                                href="mailto:ask@metallurg.ru">ask@metallurg.ru</a>.
                        </p>
                        <button class="registration-form__rules-close js-close-rules" type="button"></button>
                    </div>
                </div>
            </div>
        </div>
      <ErrorPopup
          v-if="isErrorPopupShown"
          @closeErrorPopup="closeErrorPopup"
          :errorMessage="errorMessage"
      />
    </div>
</template>

<script>
import {authorizationMapper} from '@/store/modules/authorization';
import ErrorPopup from "@/components/content/ErrorPopup.vue";

export default {
    name: 'RegisterPage',
    data() {
        return {
            error: null,
            isLoading: false,

            password: '',
            email: '',
            phone: '',
            firstname: '',
            middlename: '',
            lastname: '',
            birthday: '',
            agreement: false,
            code: '',

            stageConfirm: false,
            stageSuccess: false,
            isErrorPopupShown: false,
            errorMessage: '',
        };
    },
    components: {
      ErrorPopup,
    },
    computed: {
        ...authorizationMapper.mapGetters(['registerInfo']),
    },
    methods: {
        ...authorizationMapper.mapActions(['registerInit', 'registerConfirm']),
        closeErrorPopup(isShowModal) {
          this.isErrorPopupShown = isShowModal;
        },
        async startRegister () {
            this.isLoading = true;
            this.error = null;

            try {
                await this.registerInit({
                    password: this.password,
                    email: this.email,
                    phone: this.phone,
                    firstName: this.firstname,
                    lastName: this.lastname,
                    birthday: this.birthday,
                    middleName: this.middlename,
                });
                
                this.stageConfirm = true;
            } catch (errors) {
              this.errorMessage = errors?.errorMessage;
              this.isErrorPopupShown = true;
                this.error = errors;
                console.log(this.error)
                if (errors?.length) {
                    errors.forEach((err) => this.setErrors(err));
                } else {
                    this.setErrors(errors);
                }

            } finally {
                this.isLoading = false;
            }
        },
        async confirmRegister() {
            this.isLoading = true;
            this.error = null;

            try {
                await this.registerConfirm(this.code);

                this.stageSuccess = true;
            } catch (errors) {
                this.error = errors?.length ? errors[0] : errors;

                if (errors?.length) {
                    errors.forEach((err) => this.setErrors(err));
                } else {
                    this.setErrors(errors);
                }
            } finally {
                this.isLoading = false;
            }
        },
        setErrors (error) {
            if (error && error?.errorKey) {
                document.querySelector(`input[name=${error.errorKey}]`)
                    ?.closest('.registration-form__element')
                    ?.classList?.add('error');
            }
        },
        isEmailValid() {
            return !!(this.email && String(this.email)
                .toLowerCase()
                .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ));
        },
        isPasswordValid() {
            return !!(this.password && this.password.length >= 8 
                && String(this.password).match(/[А-ЯA-Z]/g)
                && String(this.password).match(/[а-яa-z]/g)
                && String(this.password).match(/[\d]/g));
        },
        phoneMask(event) {
            const systemKeys = ['Backspace', 'Delete', 'Enter', 'Shift'];

            if ((!String(event?.key).match(/^\d$/) || this.phone.length === 11) && !systemKeys.includes(event?.key)) {
                event?.preventDefault();
            }
        },
        phoneChange(event) {
            event.target.value = String(event.target.value).replaceAll(/\D/ig, '');
        }
    }
}
</script>

<style lang="scss" src="@/styles/RecoverPassword.scss"></style>

<template>
  <div class="regauth recover-password">
    <h1>Восстановление пароля</h1>

    <div class="cart-form recover-password-form js-recover-form">
      <div v-if="!successStage">
        <div v-if="!confirmRecoveryStage" class="element" id="element_email">
          <label>Укажите адрес электронной почты</label>
          <div class="element-right" v-if="!confirmRecoveryStage">
            <input class="req" type="text" tabindex="1" name="email" v-model="email">
            <div class="cart-form-error">
              <span v-if="error">
                {{error.errorMessage}}
              </span>
            </div>
          </div>
        </div>
        <div v-if="confirmRecoveryStage" class="element" id="element_code">
          <label v-if="confirmRecoveryStage">Подтвердите код из почты</label>
          <div class="element-right" v-if="confirmRecoveryStage">
            <input class="req" type="text" tabindex="1" name="code" v-model="code">
            <div class="cart-form-error"><span v-if="error && error?.errorKey === 'code'">{{error.errorMessage}}</span></div>
          </div>
        </div>
        <div v-if="confirmRecoveryStage" class="element" id="element_newPass">
          <label v-if="confirmRecoveryStage">Введите новый пароль</label>
          <div class="element-right" v-if="confirmRecoveryStage">
            <input class="req" type="password" tabindex="1" name="newPassword" v-model="newPassword">
            <div class="cart-form-error"><span v-if="error && error?.errorKey === 'newPassword'">{{error.errorMessage}}</span></div>
          </div>
        </div>
        <div v-if="confirmRecoveryStage" class="element ma" id="element_newPassRepeat">
          <label v-if="confirmRecoveryStage">Введите новый пароль повторно</label>
          <div class="element-right" v-if="confirmRecoveryStage">
            <input class="req" type="password" tabindex="1" name="newPasswordRepeat" v-model="newPasswordRepeat">
          </div>
        </div> 
        <button
            type="button"
            class="yellow-button submit"
            @click="confirmRecoveryStage ? confirmRecovery(code, newPassword, newPasswordRepeat) : initRecovery(email)"
            :disabled="isLoading || ((!confirmRecoveryStage) && !isEmailValid(email) || (confirmRecoveryStage && (newPassword != newPasswordRepeat || !newPassword.length || !code.length)))"
            :class="{'loading': isLoading}"
        >
          <span class="authorization-modal__come-in-title">Восстановить</span>
          <span class="loader"></span>
        </button>
      </div>
    </div>
    <div v-if="successStage" style="text-align: center;">
      <label>Пароль успешно изменен!</label>
      <a href="/" class="yellow-button go-to-auth">Авторизоваться</a>
    </div>
  </div>
</template>

<script>
import {authorizationMapper} from '@/store/modules/authorization';

export default {
  name: 'RecoverPasswordPage',
  data() {
    return {
      email: '',
      code: '',
      newPassword: '',
      newPasswordRepeat: '',
      isLoading: false,
      confirmRecoveryStage: false,
      successStage: false,
      error: null
    }
  },
  computed: {
    ...authorizationMapper.mapGetters(['passwordRecoveryInfo']),
  },
  methods: {
    ...authorizationMapper.mapActions(['passwordRecoveryInit', 'passwordRecoveryConfirm']),
    async initRecovery(email) {
      this.isLoading = true;
      this.error = null;

      try {
        await this.passwordRecoveryInit(email);
        
        this.confirmRecoveryStage = true;
      } catch (e) {
        this.error = e?.length ? e[0] : e;
      } finally {
        this.isLoading = false;
      }
    },
    async confirmRecovery(code, newPassword, newPasswordRepeat) {
      if (newPassword != newPasswordRepeat) {
        return;
      }

      this.isLoading = true;
      this.error = null;

      try {
        await this.passwordRecoveryConfirm({ code, newPassword });

        this.successStage = true;
      } catch (e) {
        this.error = e?.length ? e[0] : e;
      } finally {
        this.isLoading = false;
      }
    },
    isEmailValid() {
      return !!(this.email && String(this.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ));
    }
  }
}
</script>

<style lang="scss" src="@/styles/RecoverPassword.scss"></style>
<template>
  <div class="all">

    <div class="container" style="position: relative">
      <div class="content">
        <AuthorizationModal 
          v-if="isAuthorizationModalShown" 
          @closeAuthorizationModal="closeAuthorizationModal"
          @processSeatClick="processSeatClick" 
        />
        <div v-if="!gameInfo || !sectorInfo" class="sector-info sector-info__spinner">
          <half-circle-spinner
              :animation-duration="1000"
              :size="60"
              :color="'#0a275f'"
          />
        </div>

        <div v-else class="tickets-system-local">
          <button
            v-if="reservedSeats.length"
            type="button"
            class="js-basket-shop basket-shop detail-page tiskets"
            :class="{'fixed': isScrollOver, 'loading': isTicketsPopupLoading}"
            :disabled="isTicketsPopupLoading"
            @click="showTicketsPopup()"
          >
            <half-circle-spinner
              v-if="isTicketsPopupLoading"
              class="tickets-popup-loader"
              :animation-duration="2000"
              :size="60"
              :color="'#0a275f'"
            />
            <div class="basket-shop__wrapper">
              <div class="basket-shop__selected-info">
                <span>Выбрано:</span>
                <span v-if="basketCalendar === 1"> {{ basketCalendar }} мероприятиe</span>
                <span v-if="basketCalendar >= 2 && basketCalendar <= 4"> {{ basketCalendar }} мероприятия</span>
                <span v-if="basketCalendar >= 5"> {{ basketCalendar }} мероприятий</span>
              </div>
              <div class="basket-shop__inner">
                <div class="basket-shop__icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <path fill-rule="evenodd"
                          d="M19.99 6.59c.04-.32-.03-.6-.2-.83a.98.98 0 00-.7-.44L5.26 3.72l-.18-1.75c-.03-.21-.1-.4-.23-.57a.94.94 0 00-.47-.35l-3.05-1a1.04 1.04 0 00-.78.07 1 1 0 00-.52.63c-.07.3-.05.57.08.83.12.26.33.44.6.53l2.39.78.84 8.77-.56 3.13c-.04.33.04.63.25.9a1 1 0 00.8.38h12.88a1 1 0 00.73-.3c.2-.21.3-.47.3-.78 0-.3-.1-.56-.3-.77a1 1 0 00-.73-.3H5.67l.24-1.43h12.22c.25 0 .48-.08.68-.25.2-.17.3-.39.34-.65l.84-5zm-14 10.08c.45 0 .82.16 1.13.48.31.32.47.71.47 1.17 0 .46-.16.86-.47 1.19-.3.33-.68.49-1.12.49-.44 0-.82-.16-1.13-.5-.3-.32-.46-.72-.46-1.18A1.63 1.63 0 016 16.67zm10.55.48a1.5 1.5 0 00-1.12-.48c-.44 0-.82.16-1.13.48-.3.32-.46.71-.46 1.17 0 .46.16.86.46 1.19.31.33.69.49 1.13.49.44 0 .82-.16 1.12-.5.31-.32.47-.72.47-1.18 0-.46-.16-.85-.47-1.17z"
                          clip-rule="evenodd"></path>
                  </svg>
                </div>
                <div class="basket-shop__text basket-shop__text--margin">
                  <span class="tablet-hide">В&nbsp;корзине:</span>
                  <span>{{ basketQuantity }} 
                      <span v-if="basketQuantity === 1" class="tablet-hide">билет</span>
                      <span v-if="basketQuantity >= 2 && basketQuantity <= 4" class="tablet-hide">билета</span>
                      <span v-if="basketQuantity >= 5" class="tablet-hide">билетов</span>
                    </span>
                </div>
                <div class="basket-shop__text tablet-hide">
                  <span>на&nbsp;сумму:</span>
                  <span>{{ basketPrice }} ₽</span>
                </div>
              </div>
              <!-- <div class="basket-shop__info tablet-hide">
                  <span>Оформить заказ</span>
                  <svg>
                      <use xlink:href="#ic_arrow-right"></use>
                  </svg>
              </div> -->
            </div>
          </button>
          <div class="tickets-system-local__wrapper">
            <div class="tickets-system-local__top" v-if="gameInfo">
              <GameMainInfo :gameInfo="gameInfo"/>
              <!--
                <ul class="tickets-system-local__tabs-heading">
                  <li class="tickets-system-local__tab-heading" :class="{active: showSchema === true}">
                    <button type="button" @click="showStadiumSchema">Схема</button>
                  </li>
                  <li class="tickets-system-local__tab-heading" :class="{active: showSchema === false}">
                    <button type="button" @click="showAvailableSeats">Список</button>
                  </li>
                </ul> 
               -->
            </div>

            <div v-if="!innerSectorInfo.currentSectorName" class="tickets-system-local__main sector-info sector-info__spinner">
              <half-circle-spinner
              :animation-duration="1000"
              :size="60"
              :color="'#0a275f'"
              />
            </div>

            <div v-else class="tickets-system-local__main">
              <ul class="tickets-system-local__price-list">
                <li class="tickets-system-local__price-item" v-for="priceId in innerSectorInfo.seatSchemaPricesLegend"
                    :key="priceId.id">
                  <span>{{ priceId.price }} ₽</span>
                  <span class="tickets-system-local__price-item-line" :style="{'background-color': priceId.color}"></span>
                </li>
              </ul>

              <div lass="tickets-system-local__tabs-content">
                <div v-if="sectorInfo.leftSectorId" class="sector-info__previous-sector-wrapper">
                      <router-link :to="{ 
                          name: 'sector',
                          params: { calendarId: this.gameInfo.id, sectorId: this.sectorInfo.leftSectorId, seasonIds: this.$route.query.seasonIds},
                          query: { seasonIds: this.$route.query.seasonIds } 
                        }"
                      >
                        <button class="sector-info__previous-sector" @click="goToPreviousSector(gameInfo.id, sectorInfo.leftSectorId)">
                          <span class="sector-info__left-sector-name">{{ leftSectorName }}</span>
                          <svg class="icon">
                            <use xlink:href="#ic_arrow-right"></use>
                          </svg>
                        </button>
                      </router-link>
                    </div>
                    <div v-if="sectorInfo.rightSectorId" class="sector-info__next-sector-wrapper">
                      <router-link :to="{ 
                          name: 'sector',
                          params: { calendarId: this.gameInfo.id, sectorId: this.sectorInfo.rightSectorId, seasonIds: this.$route.query.seasonIds},
                          query: { seasonIds: this.$route.query.seasonIds }
                        }"
                      >
                        <button class="sector-info__next-sector" @click="goToNextSector(gameInfo.id, sectorInfo.rightSectorId)">
                          <svg class="icon">
                            <use xlink:href="#ic_arrow-right"></use>
                          </svg>
                          <span class="sector-info__right-sector-name">{{ rightSectorName }}</span>
                        </button>
                      </router-link>
                    </div>
                <article class="tickets-system-local__tab-content show" v-if="sectorInfo">
                  <half-circle-spinner
                    v-if="isSectorLoading"
                    class="sector-loader"
                    :animation-duration="2000"
                    :size="60"
                    :color="'#0a275f'"
                  />

                  <div class="sector-scheam" :class="{'sector-loading': isSectorLoading}">
                        
                        <img onerror="javascript:this.style.display='none'" :src="'/resources/mapnav/map' + this.$route.params.sectorId + '.svg'" alt="" class="sector-scheam__mapnav">
                        <router-link :to="{ 
                            name: 'games',
                            params: { calendarId: this.$route.params.calendarId, seasonIds: this.$route.query.seasonIds },
                            query: { seasonIds: this.$route.query.seasonIds }
                          }"
                        >
                          <button type="button" class="sector-scheam__back">
                            <svg>
                              <use xlink:href="#ic_arrow_tickets_system"></use>
                            </svg>
                            Вернуться к схеме арены
                          </button>
                        </router-link>
                        <img onerror="javascript:this.style.display='none'" :src="'/resources/mapnav/map' + this.$route.params.sectorId + '.svg'" alt="" class="sector-scheam__mapnavmodile">
                        
                    <div class="sector-scheam__inner">

                      <h3 v-if="innerSectorInfo.currentSectorName" class="sector-scheam__heading">
                        {{ innerSectorInfo.currentSectorName }}</h3>
                      <!--                      <div class="sector-scheam__inner-places">
                                              <p class="sector-scheam__places">49 свободных мест по 800₽ </p>
                                              <p class="sector-scheam__places">49 свободных мест по 800₽ </p>
                                              <p class="sector-scheam__places">49 свободных мест по 800₽ </p>
                                            </div>  -->
                      <div v-if="showSchema" class="sector-scheam__picture-wrapper" ref="container">
                        <div id="scheme" class="sector-scheam__picture" v-html="innerSectorInfo.seatSchemaHtml"
                             @click="schemeClick($event)">
                        </div>
                        <PlaceInfoLayer
                          v-if="placeInfoLayerData"
                          :data="placeInfoLayerData"
                          v-on:mouseleave="hideTooltip"
                          v-on:mouseover="clearTooltipTimeout"
                        />
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
        <TicketsPopupOrder
            v-if="isTicketsPopupShown"
            @closeTicketsPopup="closeTicketsPopup"
            @stopTicketsPopupLoading="stopTicketsPopupLoading"
            :orderReservedSeats="reservedSeats"
            :principalInfo="principalInfo"
        />
        <AuthorizationPopup 
          v-if="isAuthorizationPopupShown"
          @closeAuthorizationPopup="closeAuthorizationPopup"
          @processSeatClick="processSeatClick" 
          :isFullHeight="true"
        />
        <ErrorPopup
            v-if="isErrorPopupShown"
            @closeErrorPopup="closeErrorPopup"
            :errorMessage="errorMessage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';

import {calendarMapper} from '@/store/modules/calendar';
import {authorizationMapper} from "@/store/modules/authorization";

import {HalfCircleSpinner} from 'epic-spinners';
import GameMainInfo from './GameMainInfo.vue';
import AuthorizationModal from "../header/AuthorizationModal.vue";
import TicketsPopupOrder from './TicketsPopupOrder.vue';
import ErrorPopup from './ErrorPopup.vue';
import PlaceInfoLayer from '../layers/PlaceInfoLayer.vue';
import AuthorizationPopup from './AuthorizationPopup.vue';

const ERROR_PAGE = 'errorPage';

export default {
  name: 'SectorInfo',
  data() {
    return {
      innerGameInfo: {},
      innerSectorInfo: {},
      localEvents: 0,
      isAuthorizationModalShown: false,
      isTicketsPopupShown: false,
      isErrorPopupShown: false,
      isAuthorizationPopupShown: false,
      errorMessage: '',
      isScrollOver: false,
      showSchema: true,
      placeInfoLayerData: null,
      tooltipTimeoutId: null,
      isTicketsPopupLoading: false,
      isSectorLoading: false,
      rightSectorName: '',
      leftSectorName: '',
      onAuthModalClose: null,
      schemeClickEvent: null,
      seasonIds: []
    }
  },
  components: {
    HalfCircleSpinner,
    GameMainInfo,
    AuthorizationModal,
    TicketsPopupOrder,
    ErrorPopup,
    PlaceInfoLayer,
    AuthorizationPopup,
  },
  async mounted() {
    this.seasonIds = this.$route.query.seasonIds || [];
    await this.getReservedSeatsList();
    await this.getAvailableSeatList([this.$route.params.calendarId, this.$route.params.sectorId]);
    try {
      await this.getCalendarById(this.$route.params.calendarId);
      await this.getSchemeBySectorId({
        calendarId: this.$route.params.calendarId,
        sectorId: this.$route.params.sectorId,
        seasonIds: this.seasonIds
      });
    } catch(error) {
      if(error.status == 400) {
        router.push({ name: ERROR_PAGE });
        this.isSectorLoading = false;
      } else {
        this.isSectorLoading = false;
      }
    }

    requestAnimationFrame(() => {
      this.requestAnimationFrame();
    });

    this.innerGameInfo = this.gameInfo;
    this.innerSectorInfo = this.sectorInfo;
    // Setup the event listener and execute it once in case we are already scrolled
    window.addEventListener('scroll', this.scrollHandler);
    this.scrollHandler();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    ...calendarMapper.mapActions(['getCalendarById', 'getSchemeBySectorId', 'getReservedSeatsList', 'getAvailableSeatList', 'reserveSeat', 'unreserveSeat']),
    requestAnimationFrame() {
        this.setSeatsStyles();
        this.setRigthSectorName();
        this.setLeftSectorName();

        this.$refs.container.addEventListener('mousemove', (event) => {
        const sectorEl = event.target.closest('[data-content]');
        if (!sectorEl) {
          const schemeContainer = event.target.closest('.sector-scheam__picture');
          if (schemeContainer) {
            this.hideTooltip();
          }
          return;
        }
        const placeId = +sectorEl.getAttribute('data-content');
        const availableClass = sectorEl.getAttribute('class');
        const seatId = +sectorEl.getAttribute('id');
        const seat = this.availableSeatList.find(s => s.id === seatId);
        if (this.availableSeatList.length > 0) {
          if (seat) {
            const priceLegend = this.innerSectorInfo.seatSchemaPricesLegend.find(p => p.zoneId === seat.zoneId);
            if (!placeId) {
              this.hideTooltip();
              return;
            }
            const containerBbox = this.$refs.container.getBoundingClientRect();
            const bbox = sectorEl.getBoundingClientRect();
            this.showTooltip({
              top: containerBbox.height - (bbox.top - containerBbox.top) ,//- bbox.height / 2,
              left: bbox.left - containerBbox.left + 5 - bbox.width / 2,
              placeId: placeId,
              title: seat.name,
              price: priceLegend.price,
              className: 'seat available',//availableClass,
              reserved: availableClass,
            });
          } else {
              const reservedSeat = this.reservedSeats.find(s => s.id === seatId);
              if(!reservedSeat) {
                this.hideTooltip();
                return;
              }
              const pricereservedSeat = this.innerSectorInfo.seatSchemaPricesLegend.find(x => x.zoneId === reservedSeat.zoneId);
              if (!placeId) {
                this.hideTooltip();
                return;
              }
              const containerBbox = this.$refs.container.getBoundingClientRect();
              const bbox = sectorEl.getBoundingClientRect();
              this.showTooltip({
                top: containerBbox.height - (bbox.top - containerBbox.top) ,//- bbox.height / 2,
                left: bbox.left - containerBbox.left + 5 - bbox.width / 2,
                placeId: placeId,
                title: reservedSeat.name,
                price: pricereservedSeat.price,
                reserved: 'seat reserved',
                className: 'seat available',
              });
          }
        }
      })
    },
    stopTicketsPopupLoading(isLoading) {
      this.isTicketsPopupLoading = isLoading;
    },
    showAvailableSeats() {
      this.showSchema = false;
    },
    showStadiumSchema() {
      this.showSchema = true;
      requestAnimationFrame(() => {
      });
    },
   hideTooltip() {
      if (this.tooltipTimeoutId !== null) {
        return;
      }
      this.tooltipTimeoutId = setTimeout(() => {
        this.placeInfoLayerData = null;
      }, 250);
    },
    clearTooltipTimeout() {
      if (this.tooltipTimeoutId !== null) {
        clearTimeout(this.tooltipTimeoutId);
        this.tooltipTimeoutId = null;
      }
    },
    showTooltip(data) {
      this.clearTooltipTimeout();
      this.placeInfoLayerData = data;
    },
    closeAuthorizationModal(isShowModal, authorized) {
      if(typeof this.onAuthModalClose === 'function') {
        this.onAuthModalClose.call(this, authorized);
      }
      this.onAuthModalClose = null;
      this.isAuthorizationModalShown = isShowModal;
    },
    closeErrorPopup(isShowModal) {
      this.isErrorPopupShown = isShowModal;
    },
    async schemeClick(event) {
      this.processSeatClick(event);
    },
    async processSeatClick(event) {
      this.isSectorLoading = true;
      const localEvent = event || this.schemeClickEvent;
      if (localEvent.target.className.includes('seat available')) {
        this.schemeClickEvent = event;
        const reserveInfo = {
          "quantity": 1,
          "seasonIds": this.seasonIds,
          "seatId": localEvent.target.id,
          //  "categoryId": 1,
        }

        try {
          await this.reserveSeat({calendarId: this.gameInfo.id, reserveInfo});
          this.schemeClickEvent = null;
          const node = document.getElementById(reserveInfo.seatId);
          if (node) {
            node.classList.add('reserved');
            node.classList.remove('available');
          }
          this.isSectorLoading = false;
        } catch (e) {
          if (e.status === 401) {
            this.isSectorLoading = false;
            if (!this.gameInfo.simpleReg) {
              this.isAuthorizationModalShown = true;
            } else {
              this.isAuthorizationPopupShown = true;
            }
          } else {
            this.isSectorLoading = false;
            this.errorMessage = e.data.errorMessage;
            this.isErrorPopupShown = true;
          }
        }
      } else if (localEvent.target.className.includes('seat reserved')) {
        const unreserveInfo = {
          quantity: 1,
          seatId: localEvent.target.id
        }
        try {
          await this.unreserveSeat({calendarId: this.gameInfo.id, unreserveInfo});
          const node = document.getElementById(unreserveInfo.seatId);
          if (node) {
            node.classList.add('available');
            node.classList.remove('reserved');
          }
          this.isSectorLoading = false;
        } catch (e) {
          this.isSectorLoading = false;
          this.errorMessage = e.message;
          this.isErrorPopupShown = true;
        }
      } else {
        this.isSectorLoading = false;
      }
    },
    setSeatsStyles() {
      const wrapTable = document.querySelector('#scheme table table');
      const withWrapper = wrapTable.querySelector('tbody tr:first-child td:nth-child(2)');
      const cellsCount = +withWrapper.getAttribute('colspan');
      const CELL_SIZE = 20;
      const CELLS_PADDING = 3;
      withWrapper.style.width = `${cellsCount * CELL_SIZE + (cellsCount - 1) * CELLS_PADDING}px`;
      wrapTable.style.minWidth = `${cellsCount * CELL_SIZE + (cellsCount - 1) * CELLS_PADDING + 200}px`;


      const availableList = wrapTable.querySelectorAll('.seat');

      availableList.forEach(seat => {
        seat.setAttribute('data-content', seat.innerText);
      });
    },
    // Methods below will be used after adding dinamyc sector's ids
    async goToNextSector(gameInfoId, rightSectorId) {
      this.isSectorLoading = true;
      try {
        await this.getCalendarById(gameInfoId);
        await this.getSchemeBySectorId({
          calendarId: gameInfoId,
          sectorId: rightSectorId,
          seasonIds: this.seasonIds
        });
      } catch(error) {
        if (error.status === 400) {
          router.push({ name: ERROR_PAGE });
          this.isSectorLoading = false;
        }
      }
      await this.getAvailableSeatList([this.$route.params.calendarId, this.$route.params.sectorId]);

      setTimeout(() => {
        this.requestAnimationFrame();
      }, 250);

      this.innerGameInfo = this.gameInfo;
      this.innerSectorInfo = this.sectorInfo;
      this.isSectorLoading = false;
    },
    async goToPreviousSector(gameInfoId, leftSectorId) {
      this.isSectorLoading = true;
      try {
        await this.getCalendarById(gameInfoId);
        await this.getSchemeBySectorId({
          calendarId: gameInfoId,
          sectorId: leftSectorId,
          seasonIds: this.seasonIds
        });
      } catch(error) {
        if (error.status === 400) {
          router.push({ name: ERROR_PAGE });
          this.isSectorLoading = false;
        }
      }
      await this.getAvailableSeatList([this.$route.params.calendarId, this.$route.params.sectorId]);
      
      setTimeout(() => {
        this.requestAnimationFrame();
      }, 250);

      this.innerGameInfo = this.gameInfo;
      this.innerSectorInfo = this.sectorInfo;
      this.isSectorLoading = false;
    },

    async getReservedSeatsListUser() {
      if (this.userInfo?.userId) {
        return this.getReservedSeatsList();
      }
    },
    setRigthSectorName() {
      const tableElem = document.querySelector('#scheme table');
      this.rightSectorName = tableElem.getAttribute('rightsectorname');
    },
    setLeftSectorName() {
      const tableElem = document.querySelector('#scheme table');
      this.leftSectorName = tableElem.getAttribute('leftsectorname');
    },
    showTicketsPopup() {
      this.isTicketsPopupShown = true;

      if(!this.bonuses) {
        this.isTicketsPopupLoading = true;
      }
    },
    closeTicketsPopup(isShowTicketsPopup) {
      this.isTicketsPopupShown = isShowTicketsPopup;
    },
    closeAuthorizationPopup(isShowAuthorizationPopup, isShowAuthorizationModal) {
      if (isShowAuthorizationModal) {
        this.isAuthorizationPopupShown = isShowAuthorizationPopup;
        this.isAuthorizationModalShown = true;
      } else this.isAuthorizationPopupShown = isShowAuthorizationPopup;
    },
    scrollHandler() {
      this.isScrollOver = window.pageYOffset > 80;
    }
  },
  computed: {
    ...calendarMapper.mapGetters(['gameInfo', 'sectorInfo', 'reservedSeats', 'availableSeatList', 'bonuses']),
    ...authorizationMapper.mapGetters(['userInfo', 'principalInfo']),
    userInfoId() {
      return this.userInfo?.userId;
    },
    basketQuantity() {
      return this.reservedSeats && this.reservedSeats instanceof Array ? this.reservedSeats.reduce((accumulator, {quantity}) => {
        return accumulator + quantity;
      }, 0) : 0
    },
    basketPrice() {
      return this.reservedSeats && this.reservedSeats instanceof Array ? this.reservedSeats.reduce((accumulator, {price}) => {
        return accumulator + Number(price);
      }, 0) : 0
    },
    basketCalendar() {
      return Object.keys(
          this.reservedSeats && this.reservedSeats instanceof Array ? this.reservedSeats.reduce((accumulator, {calendarId}) => {
            accumulator[calendarId] = 1;
            return accumulator;
          }, {}) : {}).length;
    }
  },
}
</script>

<style lang="scss" src="@/styles/SectorInfo.scss">
</style>

<style scoped lang="scss">
  .tickets-popup-loader {
    position: absolute;
    left: 45%;
    top: 25%;
    z-index: 10;

    @media (max-width: 1279px) {
      display: none;
    }
  }

  .sector-loader {
    position: absolute;
    top: 45%;
    left: 50%;
    z-index: 10;
  }

  .sector-loading {
    opacity: 0.5;
    pointer-events: none;
  }
</style>

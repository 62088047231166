<template>
  <div class="subheader">
    <ul class="subheader__list">
      <li class="subheader__list-item">
        <router-link :to="{ name: 'ticket' }">
          <a class="subheader__list-item-link">Билеты</a>
        </router-link>  
      </li>
      <li class="subheader__list-item">
        <router-link :to="{ name: 'abonements' }">
          <a class="subheader__list-item-link">Абонементы</a>
        </router-link>  
      </li>
      <li v-if="this.userInfo.userEmail" class="subheader__list-item" @click="ordersHandler">
        <a class="subheader__list-item-link">Мои&#160;заказы</a>
      </li>
      <!-- <li class="subheader__list-item">
        <a>Карта точек продаж</a>
      </li>
      <li class="subheader__list-item">
        <a>VIP-ложи</a>
      </li>
      <li class="subheader__list-item">
        <a>Выездные матчи</a>
      </li> -->
    </ul>
  </div>
</template>

<script>
import router from '@/router';
import * as $ from 'jquery';
import {authorizationMapper} from "@/store/modules/authorization";
export default {
  name: 'SubHeader',
  computed: {
    ...authorizationMapper.mapGetters(['userInfo']),
  },
  methods: {
    ordersHandler() {
      const authorizedUserEmail = this.userInfo.userEmail;

      if (!authorizedUserEmail) {
        const wrapTrash = $('.subheader');
        wrapTrash.addClass('hide');
        this.isAuthorizationModalShown = true;
        return;
      } else {
        if (this.$route.name === 'orders') {
          router.go(0);
        } else {
          router.push({ name: 'orders' });
        }
      }
    },
  }
}
</script>

<style lang="scss" src="@/styles/SubHeader.scss">
</style>
